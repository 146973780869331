import './MobilePopup.scss'
import { useEffect, useState } from "react";

import close from '../../../assets/icons/close.svg'

export default function MobilePopup(props) {
  const [show, setShow] = useState(false)

  

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleClose = () => {
    setShow(false)
   
    props.onClose(false)
  }


  return (

    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className="overlay"
    >
      <div className='mobile-popup'>
        <img style={{position: 'absolute', width: '18px', top: '13px', right: '13px'}} src={close} onClick={handleClose} alt="c"/>
        <div className='mobile-popup-title'>
          Отправьте нам сообщение и мы с Вами свяжемся
        </div>
        <div className="mobile-popup-form">
          <div className='mobile-popup-inputs'>
            <input className='mobile-custom-input' placeholder='Имя'/>
          </div>
          <div className='mobile-popup-inputs'>
          <input className='mobile-custom-input' placeholder='Email'/>
          </div>
          <div className='mobile-popup-textarea'>
            <textarea className='mobile-custom-textarea' placeholder='Сообщение'/>
          </div>
        </div>
        <div className='mobile-form-button' onClick={handleClose}>
          Отправить
        </div>
        
      </div>

    </div>
  )
}