import './MobileDocuments.scss'

import doc from '../../../../assets/icons/document.svg'


import erpreg from '../../../../assets/docs/reg-erp-doc.pdf'
import erpznak from '../../../../assets/docs/znak-erp-doc.pdf'
import sved from '../../../../assets/docs/svedeniya.pdf'
import metareg from '../../../../assets/docs/reg-meta-doc.pdf'
import metaznak from '../../../../assets/docs/znak-meta-doc.pdf'

export default function MobileDocuments({documentsRef}) {
  return (
    <div className='mobile-documents' ref={documentsRef}>
      <div className='mob-doc-header'>
        Документы
      </div>
      <div className='mob-doc-desc1'>
        <div className='mob-doc-desc-title'>
          Цифровая платформа “Ares ERP”
        </div>
        <div className='mob-download'>
          <a href={erpreg} target="_blank"><img src={doc} alt="docs" /></a>
          <div className='down-desc'>Свидетельство о государственной
            регистрации программы для ЭВМ.
            № 20226682205</div>
        </div>
        <div className='mob-download'>
          <a href={erpznak} target="_blank"><img src={doc} alt="docs" /></a>
          <div className='down-desc'>
            Свидетельство на товарный знак (знак обслуживания) № 887519
          </div>
        </div>
        <div className='mob-download'>
          <a href={sved} target="_blank"><img src={doc} alt="docs" /></a>
          <div className='down-desc'>
          Сведения, содержащиеся в записи о ПО, включенном в реестр российского ПО
          </div>
        </div>
      </div>
      <div className='mob-doc-desc2'>
        <div className='mob-doc-desc-title'>
          Цифровая платформа “Ares Meta”
        </div>
        <div className='mob-download'>
          <a href={metareg} target="_blank"><img src={doc} alt="docs" /></a>
          <div className='down-desc'>
            Свидетельство о государственной регистрации программы для ЭВМ. № 2022661754
          </div>
        </div>
        <div className='mob-download'>
          <a href={metaznak} target="_blank"><img src={doc} alt="docs" /></a>
          <div className='down-desc'>
            Свидетельство на товарный знак (знак обслуживания) № 892065
          </div>
        </div>
      </div>
    </div>
  )
}