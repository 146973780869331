import { useState } from 'react';
import FeedbackPopup from '../../FeedbackPopup/FeedbackPopup';
import './Contacts.scss'

export default function Contacts(props){
  const{
    contactsRef 
  } = props
  const [visibility, setVisibility] = useState(false);

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const openPopup = (e) => {
    setVisibility(!visibility)
  }
  return(
    <div className="contacts" ref={contactsRef}>
      <div className="contacts-title">Контакты</div>
      <div className="contacts-info">
        <div className="info">
          <div className="info-desc">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ТЕХНОЛОГИИ АРЕС"</div>
          <div className="info-desc">143003, РОССИЯ, МОСКОВСКАЯ ОБЛ, Г ОДИНЦОВО, УЛ МАРШАЛА НЕДЕЛИНА, Д 6А, Э.7 </div>
          <div className="info-feedback"><span style={{color: '#00A3FF', textDecoration: 'underline', cursor: 'pointer'}}>support@techno-ares.ru;</span></div>
          <div className="contacts-button" onClick={openPopup}>Связаться с нами</div>
        </div>
        
        <div className="map">
          <iframe style={{borderRadius: '14px', width: '100%'}}src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa73c8482ba9405acd38fe535fd13301cbdb27c704f7428c3f671d178457f007a&amp;source=constructor" width="815" height="462" frameborder="0"></iframe>
        </div>
      </div>
      <FeedbackPopup onClose={popupCloseHandler}
                show={visibility}/>
    </div>
  )
}