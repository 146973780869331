import './Products.scss'

import erpBanner from '../../../assets/img/erpBanner.png'
import metaBanner from '../../../assets/img/metaBanner.svg'

import corp from '../../../assets/icons/erpFunctions/corp.svg'
import logistics from '../../../assets/icons/erpFunctions/logistics.svg'
import platform from '../../../assets/icons/erpFunctions/platform.svg'
import production from '../../../assets/icons/erpFunctions/production.svg'
import quality from '../../../assets/icons/erpFunctions/quality.svg'
import human from '../../../assets/icons/erpFunctions/human.svg'
import services from '../../../assets/icons/erpFunctions/services.svg'

const functions = [
  {
    id: 1,
    title: "Корпоративное управление",
    img: corp
  },
  {
    id: 2,
    title: "Логистика",
    img: logistics
  },
  {
    id: 3,
    title: "Корпоративная платформа",
    img: platform
  },
  {
    id: 4,
    title: "Производство",
    img: production
  },
  {
    id: 5,
    title: "Управление качеством",
    img: quality
  },
  {
    id: 6,
    title: "Управление человеческим ресурсом",
    img: human
  },
  {
    id: 7,
    title: "Сервисы и обслуживание",
    img: services
  }
]

export default function Products({ productsRef }) {
  return (
    <div className="products" ref={productsRef}>
      <div className='erp'>
        <img src={erpBanner} style={{ width: '100%' }} alt="erp" />
        <div className="erp-description">
          <div className="erp-description-title">
            Удобные сервисы для реальных потребностей на одной платформе
          </div>
          <div className="erp-bigger-than">
            <div className="erp-bigger-than-title">
              Больше, чем ERP
            </div>
            <div className="erp-bigger-than-desc">
              Подлинная эффективность на основе передовых технологий
            </div>
          </div>
          <div className="erp-functions">
            <div className="erp-functions-title">
              Функции цифровой платформы Ares ERP:
            </div>
            <div className="erp-functions-list">
              {functions.map(el => (
                <div key={el.id} className="list-element">
                  <img src={el.img} alt="func" />
                  <div className="list-title">
                    {el.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="others">
            <div className="others-title">
              Владея информацией - управляйте развитием:
            </div>
            <div className='act'>
              <div className='act-title'>
                АКТУАЛЬНО
              </div>
              <div className='act-desc'>
                - с помощью цифровой платформы «Ares ERP» вы всегда будете держать руку на пульсе значимых процессов и событий;
              </div>
            </div>
            <div className='act'>
              <div className='act-title'>
                ДОСТОВЕРНО
              </div>
              <div className='act-desc'>
                - в любой момент времени вам доступны достоверные данные - об этом заботятся ваши интеллектуальные помощники;
              </div>
            </div>
            <div className='act'>
              <div className='act-title'>
                РЕАЛЬНО
              </div>
              <div className='act-desc'>
                - вы видите только реальную картину и образ будущего для наилучших управленческих решений.
              </div>

            </div>
          </div>
          <div className='effective'>
            Управляйте эффективно!
          </div>
          <div className='btncont'>
          <a style={{textDecoration: 'none'}} href="https://areserp.techno-ares.ru/#/">  <div className="href-button2" >Подробнее</div></a>
          </div>
      
        </div>
      
        
      </div>
      <div className='meta'>
        <img src={metaBanner} style={{ width: '100%' }} alt="meta" />
        <div className="meta-description">
          <div className='meta-title'>
            Новая реальность о которой говорит весь мир
          </div>
          <div className='meta-title-desc'>
            Ваша персональная Вселенная на расстоянии вытянутой руки.
          </div>
          <div className='meta-tasks'>
            <div className='meta-tasks-title'>Какие задачи мы решаем прямо сейчас?</div>
            <div className='meta-tasks-list'>
              <ul>
                <li>Проведение конференций в персональных мирах Метавселенной в режиме реального времени с полным эффектом присутствия.</li>
                <li>Формирование единой среды для работы и коммуникаций одновременно для 40 000 человек.</li>
                <li>Технологии web 3 для эффективных коммуникаций.</li>
                <li>Создание фокус-групп.</li>
                <li>Проведение масштабных презентаций, научных и образовательных активностей.</li>
              </ul>
            </div>
          </div>

          <div className='speed'>
            <div className='speed-title'>
              Скорость и качество обмена информацией
            </div>
            <div className='speed-desc'>
              Сегодня лидером рынка становится тот, чья команда оперативно принимает качественные решения.
              Для этого вам поможет цифровое пространство АРЕС МЕТА
              С нами – побеждают!

            </div>
          </div>

          <div className='speed'>
            <div className='speed-title'>
              Арес Мета
            </div>
            <div className='speed-desc'>
              Высокотехнологичная компания нового поколения <br />
              Наша миссия: Развивать мир, развивая технологии!


            </div>
          </div>
             </div>
             <a style={{textDecoration: 'none'}} href="https://aresmeta.techno-ares.ru/#/">  <div style={{left: '40px'}} className="href-button" >Подробнее</div></a>
     
      </div>
    </div>
  )
}