import './MobileAbout.scss'

import accredit from '../../../../assets/docs/accredit.pdf'
import reestr from '../../../../assets/docs/reestr.pdf'

export default function MobileAbout({aboutRef}){
  return(
    <div className='mobile-about' ref={aboutRef}>
      <div className='mobile-about-title'>
      Технологии Арес - <span style={{color: '#FFE500'}}>развиваем мир, развивая технологии</span>
      </div>

      <div className='mobile-about-desc'>
        <div>
        «Технологии Арес» - отечественная <a href={accredit} download="Аккредитация" style={{cursor: 'pointer', color: 'white', textDecoration: 'none', borderBottom: '1px solid white'}}>аккредитованная</a> ИТ-компания.

        </div>
        <div style={{marginTop: '10px'}}>
        Компания создана в 2022 году командой разработчиков, которая с 2016 года успешно разрабатывает и внедряет цифровые платформы.
        </div>
        <div style={{marginTop: '10px'}}>
        Компания «Технологии Арес» включена в <a href={reestr} download="Единый реестр"style={{cursor: 'pointer', color: 'white', textDecoration: 'none', borderBottom: '1px solid white'}}>Единый реестр</a> субъектов малого и среднего предпринимательства.
        </div>
      </div>
    </div>
  )
}