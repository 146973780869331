import './MobileHeader.scss'
import mainLogo from '../../../assets/icons/mainLogo.svg'
import burger from '../../../assets/icons/burger.svg'
import closeBurger from '../../../assets/icons/closeBurger.svg'
import { useState } from 'react'

export default function MobileHeader({route, aboutRef, mediaRef, productsRef, contactsRef, documentsRef, newsRef,}){
  const [burgered, setBurgered] = useState(false)

  const handleBurder = () => {
    setBurgered(!burgered)
  }

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView()
    setBurgered(false)
  }

  const scrollToNews = () => {
    newsRef.current.scrollIntoView()
    setBurgered(false)
  }

  const scrollToProducts = () => {
    productsRef.current.scrollIntoView()
    setBurgered(false)
  }

  const scrollToDocuments = () => {
    documentsRef.current.scrollIntoView()
    setBurgered(false)
  }

  const scrollToMedia = () => {
    mediaRef.current.scrollIntoView()
    setBurgered(false)
  }

  const scrollToContacts = () => {
    contactsRef.current.scrollIntoView()
    setBurgered(false)
  }

  return(
    <div className='mobile-header'>
      <img src={mainLogo} width={190} alt="logo"/>
      <img src={burger} alt="burger" onClick={handleBurder}/>

      {burgered &&
      <div style={{position: 'fixed', top:0, right: 0, height: '100vh', width: '60%', background: '#222222', zIndex: 2000,  padding: '40px 24px'}}>
       
       <div style={{display: 'flex', justifyContent: 'space-between',}}><div className='mob-lang'>
          <div className='mob-en'>
            EN
          </div>
          <hr/>
          <div className='mob-ru'>
            RU
          </div>
        </div>
        <div>
          <img src={closeBurger} alt="cl" onClick={handleBurder}/>
        </div></div> 
        <div className='mobile-nav'>
          <div className='mobile-nav-el' style={{color: route === "about" ? '#FFE500' : ''}} onClick={scrollToAbout}>О КОМПАНИИ</div>
          <div className='mobile-nav-el' style={{color: route === "products" ? '#FFE500' : ''}} onClick={scrollToProducts}>ПРОДУКТЫ</div>
          <div className='mobile-nav-el' style={{color: route === "news" ? '#FFE500' : ''}} onClick={scrollToNews}>НОВОСТИ</div>
          <div className='mobile-nav-el' style={{color: route === "documents" ? '#FFE500' : ''}} onClick={scrollToDocuments}>ДОКУМЕНТЫ</div>
          <div className='mobile-nav-el' style={{color: route === "media" ? '#FFE500' : ''}} onClick={scrollToMedia}>МЕДИАТЕКА</div>
          <div className='mobile-nav-el' style={{color: route === "contacts" ? '#FFE500' : ''}} onClick={scrollToContacts}>КОНТАКТЫ</div>
        </div>
        
       
      </div>
      }

      
    </div>
  )
}