import './NewsCard.scss'
import heart from '../../assets/icons/heart.svg'
import comment from '../../assets/icons/comment.svg'

export default function NewsCard(props) {

  const {
    openPopup,
    setClicked,
    newsNum
  } = props


  function openNewsPopup() {
    openPopup()
    setClicked(newsNum)
  }

  return(
    <div className="card">
      <div >
      <img className="card-img" src={props.newsEl.img} alt="news-img"/>
      <div className="card-date">{props.newsEl.date}</div>
      </div>
     <div>
      
     </div>
      <div className="card-title">{props.newsEl.title}</div>
      {newsNum !== 0 ?
      <div className="card-description">{props.newsEl.description.substr(0, 300)}...</div> :
        <div className="card-description" style={{marginLeft: '-20px'}}>
          <div>Темы для обсуждения:</div>
          <div style={{marginLeft: '10px', marginTop: '10px'}}>1. «ГосТех» для разработчиков и регионов. </div>
          <div style={{marginLeft: '10px', marginTop: '3px'}}>2. Региональный пилот: проектный офис в Петербурге<br/> – первые итоги и дальнейший план работы... </div>
  
        </div>}
      <div className="card-button" onClick={openNewsPopup}>Подробнее</div>
      <div className="card-feedback">
        <div className='feedback-section'>
          <img src={heart} alt="like"/>
          <div>{props.newsEl.likes}</div>
        </div>
        <div className='feedback-section2'>
          <img src={comment} style={{marginTop: '5px'}} alt="comments"/>
          <div>Комментарии: {props.newsEl.comments}</div>
        </div>
      </div>
    </div>
  )
}