import './MediaLibrary.scss'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { Tabs, Tab } from '@mui/material';
import media1 from '../../../assets/img/media1.svg';
import media2 from '../../../assets/img/media2.svg';
import media3 from '../../../assets/img/media3.svg';
import { useState } from 'react';

import video from '../../../assets/videos/ares_meta.mp4'

export default function MediaLibary({ mediaRef }) {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelect = (e) => {
    console.log('hello', e)
  }

  return (
    <div className="media-lib" ref={mediaRef}>
      <div className="media-lib-header">
        Медиатека
      </div>
      <div className="tabs-container">
        <Tabs style={{ background: 'rgba(34, 34, 34, 0.5)', borderRadius: '128px', width: '541px' }}

        >
          <Tab label="Ares ERP" style={{ borderRadius: '128px', width: '50%', color: '#83898E' }} />
          <Tab label="Ares Meta" style={{ borderRadius: '128px', width: '50%', color: '#FFC700', background: '#222222' }} />
        </Tabs>
      </div>
      <div>
        <video
          width="30%"
          height="30%"

          controls
          playsInline
          preload='true'
          muted
          style={{ borderRadius: '14px' }}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
      {/* <Swiper
        modules={[Navigation]}
        spaceBetween={1}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}

        breakpoints={{
          960: {
            slidesPerView: 1,
          },
          1150: {
            slidesPerView: 1,
          },
          1300: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 2,
          },
          1600: {
            slidesPerView: 2,
          },
          1780: {
            slidesPerView: 2,

          },

          1920: {
            slidesPerView: 3,

          }

        }}
      >
        <SwiperSlide><img src={media1} alt="media" /></SwiperSlide>
        <SwiperSlide><img src={media2} alt="media" /></SwiperSlide>
        <SwiperSlide><img src={media3} alt="media" /></SwiperSlide>
        <SwiperSlide><img src={media3} alt="media" /></SwiperSlide>
      </Swiper> */}
    </div>
  )
}