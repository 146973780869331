import About from "../../components/sections/About/About";
import Header from "../../components/Header/Header";
import Products from "../../components/sections/Products/Products";
import News from "../../components/sections/News/News";
import Documents from "../../components/sections/Documents/Documents";
import MediaLibary from "../../components/sections/MediaLibrary/MediaLibrary";
import Contacts from "../../components/sections/Contacts/Contacts";
import Footer from "../../components/Footer/Footer";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import MobileHeader from "../../mobile/components/MobileHeader/MobileHeader";
import MobileAbout from "../../mobile/components/sections/MobileAbout/MobileAbout";
import MobileProducts from "../../mobile/components/sections/MobileProducts/MobileProducts";
import MobileNews from "../../mobile/components/sections/MobileNews/MobileNews";
import MobileDocuments from "../../mobile/components/sections/MobileDocuments/MobileDocuments";
import MobileMediaLibrary from "../../mobile/components/sections/MobileMediaLibrary/MobileMediaLibrary";
import MobileContacts from "../../mobile/components/sections/MobileContacts/MobileContacts";
import MobileFooter from "../../mobile/components/MobileFooter/MobileFooter";

export default function MainPage() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [route, setRoute] = useState('')
  

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });


    if (scrollPosition > 0 && scrollPosition < 285) {
      setRoute('about')
    }

    if (scrollPosition > 285 && scrollPosition < 1570) {
      setRoute('products')
    }

    if (scrollPosition > 1570 && scrollPosition < 2384) {
      setRoute('news')
    }

    if (scrollPosition > 2384 && scrollPosition < 2790) {
      setRoute('documents')
    }

    if (scrollPosition > 2790 && scrollPosition < 3362) {
      setRoute('media')
    }

    if (scrollPosition > 3362) {
      setRoute('contacts')
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition, route]);

  const aboutRef = useRef(null)
  const productsRef = useRef(null)
  const newsRef = useRef(null)
  const documentsRef = useRef(null)
  const mediaRef = useRef(null)
  const contactsRef = useRef(null)

  const isMobile = useMediaQuery({ query: '(max-width: 475px)' })

  return (
    <>
      {!isMobile &&
        <>
          <Header
            aboutRef={aboutRef}
            productsRef={productsRef}
            newsRef={newsRef}
            documentsRef={documentsRef}
            mediaRef={mediaRef}
            contactsRef={contactsRef}
            route={route}

          />
          <About aboutRef={aboutRef} />
          <Products productsRef={productsRef} />
          <News newsRef={newsRef} />
          <Documents documentsRef={documentsRef} />
          <MediaLibary mediaRef={mediaRef} />
          <Contacts contactsRef={contactsRef} />
          <Footer />
        </>
      }

      {isMobile &&
        <>
          <MobileHeader route={route} aboutRef={aboutRef}
            productsRef={productsRef}
            newsRef={newsRef}
            documentsRef={documentsRef}
            mediaRef={mediaRef}
            contactsRef={contactsRef}/>
          <MobileAbout aboutRef={aboutRef}/>
          <MobileProducts productsRef={productsRef}/>
          <MobileNews newsRef={newsRef}/>
          <MobileDocuments documentsRef={documentsRef}/>
          <MobileMediaLibrary mediaRef={mediaRef}/>
          <MobileContacts contactsRef={contactsRef}/>
          <MobileFooter/>
      

        </>
      }

    </>

  )
}