import { useState } from 'react';
import FeedbackPopup from '../../../../components/FeedbackPopup/FeedbackPopup';
import MobilePopup from '../../MobilePopup/MobilePopup';
import './MobileContacts.scss'

export default function MobileContacts({contactsRef}) {
  const [visibility, setVisibility] = useState(false);

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const openPopup = (e) => {
    setVisibility(!visibility)
  }
  return (
    <div className='mobile-contacts' ref={contactsRef}>
      <div className='mob-cont-title'>Контакты</div>
      <div>
        <iframe style={{ borderRadius: '4px' }} src="https://yandex.ru/map-widget/v1/?um=constructor%3A0e44ce08a6320ed27fcd1b494891f39bc0dbc233779db79cc637cb66480541eb&amp;source=constructor" width="390" height="240" frameborder="0"></iframe>
      </div>
      <div className='mob-cont-desc'>
        <div style={{marginTop: '16px'}}>
          ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ТЕХНОЛОГИИ АРЕС"
        </div>
        <div style={{marginTop: '16px'}}>
          143003, РОССИЯ, МОСКОВСКАЯ ОБЛ, Г ОДИНЦОВО, УЛ МАРШАЛА НЕДЕЛИНА, Д 6А, Э.7
        </div>
        <div style={{marginTop: '16px'}}>


          <span style={{color: '#00A3FF', textDecoration: 'underline'}}>Support@techno-ares.ru;</span>
        </div>
    


      </div>
      <div className='mob-cont-button' onClick={openPopup}>
          Связаться с нами
        </div>
        <MobilePopup onClose={popupCloseHandler}
                show={visibility}/>
    </div>
  )
}