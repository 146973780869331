import './MobileProducts.scss'

import mobErp from '../../../../assets/img/mobErp.png'
import mobMeta from '../../../../assets/img/mobileMeta.svg'

import corp from '../../../../assets/icons/erpFunctions/corp.svg'
import logistics from '../../../../assets/icons/erpFunctions/logistics.svg'
import platform from '../../../../assets/icons/erpFunctions/platform.svg'
import production from '../../../../assets/icons/erpFunctions/production.svg'
import quality from '../../../../assets/icons/erpFunctions/quality.svg'
import human from '../../../../assets/icons/erpFunctions/human.svg'
import services from '../../../../assets/icons/erpFunctions/services.svg'


export default function MobileProducts({productsRef}) {
  return (
    <div className='mobile-products' ref={productsRef}>
      <div>
        <img src={mobErp} style={{ width: '100%', marginTop: '-2px' }} alt="erpr" />
      </div>
      <div className='mob-erp-desc'>
        <div className='mob-erp-desc-title'>
          Удобные сервисы для реальных потребностей на одной платформе
        </div>
        <div className='mob-erp-desc-info'>
          <span>
            Больше, чем ERP<br />
          </span>
          <span>
            Подлинная эффективность на основе передовых технологий
          </span>
        </div>
        <div className='mob-erp-desc-functions'>
          <div className='mob-erp-func-title'>
            Функции цифровой платформы Ares ERP:
          </div>
          <div className='mob-erp-func-list'>
            <div className='mob-erp-func-el'>
              <img src={corp} alt="func" />
              <div className='mob-el-title'>
                Корпоративное управление
              </div>

            </div>
            <div className='mob-erp-func-el'>
              <img src={logistics} alt="func" />
              <div className='mob-el-title'>Логистика</div>
            </div>
            <div className='mob-erp-func-el'>
              <img src={platform} alt="func" />
              <div className='mob-el-title'>
                Корпоративная платформа
              </div>
            </div>
            <div className='mob-erp-func-el'>
              <img src={production} alt="func" />
              <div className='mob-el-title'>
                Производство
              </div>
            </div>
            <div className='mob-erp-func-el'>
              <img src={quality} alt="func" />
              <div className='mob-el-title'>
                Управление   качеством
              </div>
            </div>
            <div className='mob-erp-func-el'>
              <img src={human} alt="func" />
              <div className='mob-el-title'>
                Управление
                человеческим ресурсом
              </div>
            </div>
            <div className='mob-erp-func-el'>
              <img src={services} alt="func" />
              <div className='mob-el-title'>
                Сервисы
                и обслуживание
              </div>
            </div>

          </div>
        </div>
        <a style={{textDecoration: 'none'}} href="https://areserp.techno-ares.ru/#/">    <div className='mob-erp-desc-button'>
          Подробнее
        </div></a>
    
      </div>
      <div>
        <img src={mobMeta} style={{ width: '100%' }} alt="erpr" />
      </div>
      <div className='mob-meta-desc'>
        <div className='mob-meta-desc-title'>
          Новая реальность<br/> о которой говорит весь мир
        </div>

        <div className='mob-meta-desc-info'>
          Ваша персональная Вселенная на расстоянии вытянутой руки.
        </div>

        <div className='mob-meta-desc-tasks'>
          Какие задачи мы решаем прямо сейчас?
          <ul>
            <li>Проведение конференций в персональных мирах Метавселенной в режиме реального времени с полным эффектом присутствия.</li>
            <li>Формирование единой среды для работы и коммуникаций одновременно для 40 000 человек.</li>
            <li>Технологии web 3 для эффективных коммуникаций.</li>
            <li>Создание фокус-групп.</li>
            <li>Проведение масштабных презентаций, научных и образовательных активностей.</li>
          </ul>
        </div>

        <div className='mob-meta-desc-speed'>
          Скорость и качество обмена информацией
        </div>

        <div className='mob-meta-desc-footer'>
          Сегодня лидером рынка становится тот, чья команда оперативно принимает качественные решения.
        </div>
        <a style={{textDecoration: 'none'}} href="https://aresmeta.techno-ares.ru/#/">  <div className='mob-meta-desc-button'>
          Подробнее
        </div> </a>
      
      </div>
    </div>
  )
}