import './Header.scss'

import mainLogo from '../../assets/icons/mainLogo.svg'

export default function Header(props) {
  const {
    aboutRef,
    newsRef,
    productsRef,
    documentsRef,
    mediaRef,
    contactsRef,
    route
  } = props

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView()
  }

  const scrollToNews = () => {
    newsRef.current.scrollIntoView()
  }

  const scrollToProducts = () => {
    productsRef.current.scrollIntoView()
  }

  const scrollToDocuments = () => {
    documentsRef.current.scrollIntoView()
  }

  const scrollToMedia = () => {
    mediaRef.current.scrollIntoView()
  }

  const scrollToContacts = () => {
    contactsRef.current.scrollIntoView()
  }
  return(
    <header className="header">
      <div>
        <img src={mainLogo} alt="main-logo"/>
      </div>
      <div className='header-list'>
        <div className='header-list-element' style={{color: route === "about" ? '#FFE500' : '', borderBottom: route === "about" ? '1px solid #FFE500' : '' }} onClick={scrollToAbout}>
          О компании
        </div>
        <div className='header-list-element' style={{color: route === "products" ? '#FFE500' : '', borderBottom: route === "products" ? '1px solid #FFE500' : '' }} onClick={scrollToProducts}>
          Продукты
        </div>
        <div className='header-list-element' style={{color: route === "news" ? '#FFE500' : '', borderBottom: route === "news" ? '1px solid #FFE500' : '' }} onClick={scrollToNews}>
          Новости
        </div>
        <div className='header-list-element' style={{color: route === "documents" ? '#FFE500' : '', borderBottom: route === "documents" ? '1px solid #FFE500' : '' }} onClick={scrollToDocuments}>
          Документы
        </div>
        <div className='header-list-element' style={{color: route === "media" ? '#FFE500' : '', borderBottom: route === "media" ? '1px solid #FFE500' : '' }} onClick={scrollToMedia}>
          Медиатека
        </div>
        <div className='header-list-element' style={{color: route === "contacts" ? '#FFE500' : '', borderBottom: route === "contacts" ? '1px solid #FFE500' : '' }} onClick={scrollToContacts}>
          Контакты
        </div>
      </div>
      <div className='lang'>
        <div className='en'>
          EN
        </div>
        <hr/>
        <div className='ru'>
          RU
        </div>
      </div>
    </header>
  )
}