import './MobileNewsCard.scss'

export default function MobileNewsCard(props) {
  const {
    openPopup,
    setClicked,
    newsNum
  } = props


  function openNewsPopup() {
    openPopup()
    setClicked(newsNum)
  }

  return (
    <div className="mobile-card">
      <div>
        <img className="mobile-card-img" src={props.newsEl.img} alt="news-img" />
        <div className="mobile-card-date">{props.newsEl.date}</div>
      </div>

      <div className="mobile-card-title">{props.newsEl.title}</div>
      <div className="mobile-card-description">{props.newsEl.description.substr(0, 240)}...</div>
      <div className="mobile-card-button" onClick={openNewsPopup}>Подробнее</div>

    </div>
  )
}