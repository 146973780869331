import './NewsPopup.scss'
import { useEffect, useState } from "react";

import close from '../../assets/icons/close.svg'

export default function NewsPopup(props) {
  const [show, setShow] = useState(false)



  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleClose = () => {
    setShow(false)

    props.onClose(false)
  }


  return (

    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className="news-overlay"
    >
      <div className='news-popup'>
        <img className="close" src={close} alt="close" onClick={handleClose} />

        <div className="news-popup-title">{props.news[props.clicked]?.title} </div>
        <div className="news-popup-date">{props.news[props.clicked]?.date} </div>
        <img src={props.news[props.clicked]?.img2} style={{ height: '384px', width: '98%', marginTop: '20px', borderRadius: '20px' }} />

        {props.clicked === 0 &&
          <div style={{ textAlign: 'left' }}>
            <div className='firstNews' >Темы для обсуждения:</div>
            <div className="firstNews" >1. «ГосТех» для разработчиков и регионов. </div>
            <div className="firstNews">2. Региональный пилот: проектный офис в Петербурге – первые итоги и дальнейший план работы </div>
            <div className="firstNews"> 3. Создание сети центров компетенций по ЕЦП «ГосТех».</div>
            <div className="firstNews"> 4. Популяризация ЕЦП «ГосТех» - хакатоны, информационное продвижение. </div>
            <div className="firstNews">5. Краудсорсинговая платформа ЕЦП «ГосТех». </div>
            <div className="firstNews"> 6. Доступ разработчиков к среде ЕЦП «ГосТех». </div>
            <div className="firstNews"> 7. Мультитенантность.</div>
            <div className="firstNews"> 8. Информационная безопасность систем на ЕЦП «ГосТех». </div>
            <div className="firstNews">9. Экспериментальный правовой режим для участников ЕЦП «ГосТех». </div>
            <div className="firstNews">10. «ГосТех» для целей и задач укрепления обороноспособности Российской Федерации. </div>



          </div>

        }

        {props.clicked === 1 &&
          <div style={{ textAlign: 'left' }}>
            <div className='firstNews' >Цифровая платформа «Ares ERP» успешно внедрена в компанию «ЦСК» и обеспечивает эффективную работу головной компании, а также территориально распределённых филиалов и всей логистики, что позволило увеличить выручку компании по итогам 1 полугодия 2022 года на 20%.</div>




          </div>

        }

        {props.clicked === 2 &&
          <div style={{ textAlign: 'left' }}>

            <div className="firstNews" >Предметом соглашения является развитие сотрудничества в области образования и науки, направленного на удовлетворение потребностей в подготовке кадров и повышении квалификации сотрудников.  </div>
            <div className="firstNews">Главной задачей является установить долгосрочное и взаимовыгодное сотрудничество по следующим основным направлениям: </div>
            <div className="firstNews">1. Организация практик и стажировок, обучающихся по программам бакалавриата, магистратуры, аспирантуры Университета в Организации и трудоустройство выпускников.</div>
            <div className="firstNews">2.  Разработка и реализация специальных образовательных программ с привлечением методических специалистов Университета и профессиональных экспертов. </div>
            <div className="firstNews">3. Организация профессиональных мастер-классов, соревнований, олимпиад, конкурсных отборов научно-исследовательских и инновационных проектов по приоритетным направлениям научно- технологического развития Российской Федерации. </div>
            <div className="firstNews">4. Создание специализированной исследовательской лаборатории с целью развития новых прогрессивных форм инновационной деятельности и научно-исследовательского сотрудничества. </div>

          </div>

        }

        {props.clicked === 3 &&
          <div style={{ textAlign: 'left' }}>

            <div className="firstNews" >Успешно завершены работы по интеграции с платформами «Крибрум» и«Дневник.ру». Интеграция позволит получать доступ к платформам через виртуальную среду.</div>
          </div>

        }


      </div>

    </div>
  )
}