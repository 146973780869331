import './Documents.scss'

import doc from '../../../assets/icons/document.svg'

import erpreg from '../../../assets/docs/reg-erp-doc.pdf'
import erpznak from '../../../assets/docs/znak-erp-doc.pdf'
import sved from '../../../assets/docs/svedeniya.pdf'
import metareg from '../../../assets/docs/reg-meta-doc.pdf'
import metaznak from '../../../assets/docs/znak-meta-doc.pdf'

export default function Documents({documentsRef}) {
  return (
    <div className="documents" ref={documentsRef}>
      <div className="documents-header">
        Документы
      </div>
      <div className="documents-description">
        <div className="doc-erp">
          <div className="doc-erp-title">
            Цифровая платформа “Ares ERP”
          </div>
          <div className="doc-erp-list">
            <div className="doc-erp-list-el">
              <div className="doc-erp-list-title">Свидетельство о государственной регистрации программы для ЭВМ. № 20226682205</div>
              <a href={erpreg} target="_blank"><img src={doc} alt="doc" /></a>
            </div>
            <div className="doc-erp-list-el">
              <div className="doc-erp-list-title">
                Свидетельство на товарный знак (знак обслуживания) № 887519
              </div>
              <a href={erpznak} target="_blank"><img src={doc} alt="doc" /></a>
            </div>
            <div className="doc-erp-list-el">
              <div className="doc-erp-list-title">
                Сведения, содержащиеся в записи о ПО, включенном в реестр российского ПО
              </div>
              <a href={sved} target="_blank"><img src={doc} alt="doc" /></a>
            </div>
          </div>
        </div>
        <div className="doc-meta">
          <div className="doc-meta-title">
            Цифровая платформа “Ares Meta”
          </div>
          <div className="doc-meta-list">
            <div className="doc-meta-list-el">
              <div className="doc-meta-list-title">Свидетельство о государственной регистрации программы для ЭВМ. № 2022661754</div>
              <a href={metareg} target="_blank"><img src={doc} alt="doc" /></a>
            </div>
            <div className="doc-meta-list-el">
              <div className="doc-meta-list-title">
                Свидетельство на товарный знак (знак обслуживания) № 892065 
              </div>
              <a href={metaznak} target="_blank"><img src={doc} alt="doc" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}