import './About.scss'
import accredit from '../../../assets/docs/accredit.pdf'
import reestr from '../../../assets/docs/reestr.pdf'

export default function About({aboutRef}){
  return(
    <div className="about" ref={aboutRef}>
      <div>
        <span className='title'>Технологии Арес - <span style={{color: '#FFE500'}}>развиваем мир, развивая технологии</span></span>
      </div>
  
      <div className='desc'>
        <span className='description'>«Технологии Арес» - отечественная <a href={accredit} download="Аккредитация" style={{cursor: 'pointer', color: 'white', textDecoration: 'none', borderBottom: '1px solid white'}}>аккредитованная</a> ИТ-компания.</span>
        <span className='description'>Компания создана в 2022 году командой разработчиков, которая с 2016 года успешно разрабатывает и внедряет цифровые платформы.</span>
        <span className='description'>Компания «Технологии Арес» включена в <a href={reestr} download="Единый реестр"  style={{cursor: 'pointer', color: 'white', textDecoration: 'none', borderBottom: '1px solid white'}}>Единый реестр</a> субъектов малого и среднего предпринимательства.</span>
      </div>
      
    </div>
  )
}