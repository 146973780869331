import './MobileMediaLibrary.scss'

// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';

import { Tabs, Tab } from '@mui/material';
// import media1 from '../../../../assets/img/media1.svg';
// import media2 from '../../../../assets/img/media2.svg';
// import media3 from '../../../../assets/img/media3.svg';
// import { useState } from 'react';
import video from '../../../../assets/videos/ares_meta.mp4'

export default function MobileMediaLibrary({ mediaRef }) {
  return (
    <div className='mobile-media-lib' ref={mediaRef}>
      <div className='mobile-media-title'>
        Медиатека
      </div>
      <div className="mob-tabs-container">
        <Tabs style={{ background: 'rgba(34, 34, 34, 0.5)', borderRadius: '128px', width: '358px' }}

        >
          <Tab label="Ares ERP" style={{ borderRadius: '128px', width: '50%', color: '#83898E' }} />
          <Tab label="Ares Meta" style={{ borderRadius: '128px', width: '50%', color: '#FFC700', background: '#222222' }} />
        </Tabs>
      </div>

      {/* <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={1}
        slidesPerView={1}
        navigation
        pagination
        scrollbar={{ draggable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide><img src={media1} style={{ width: '361px' }} alt="media" /></SwiperSlide>
        <SwiperSlide><img src={media2} style={{ width: '361px' }} alt="media" /></SwiperSlide>
        <SwiperSlide><img src={media3} style={{ width: '361px' }} alt="media" /></SwiperSlide>
        <SwiperSlide><img src={media3} style={{ width: '361px' }} alt="media" /></SwiperSlide>
      </Swiper> */}
      <div>
        <video
          width="90%"
          height="90%"

          controls
          playsInline
          preload='true'
          muted
          style={{ borderRadius: '14px' }}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}