import './FeedbackPopup.scss'
import { useEffect, useState } from "react";

import close from '../../assets/icons/close.svg'

export default function FeedbackPopup(props) {
  const [show, setShow] = useState(false)

  

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleClose = () => {
    setShow(false)
   
    props.onClose(false)
  }


  return (

    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className="overlay"
    >
      <div className='popup'>
        <img className="feedback-close" src={close} alt="cl" onClick={handleClose}/>
        <div className='popup-title'>
          Отправьте нам сообщение и мы с Вами свяжемся
        </div>
        <div className="popup-form">
          <div className='popup-inputs'>
            <input className='custom-input' placeholder='Имя'/>
            <input className='custom-input' placeholder='Email'/>
          </div>
          <div className='popup-textarea'>
            <textarea className='custom-textarea' placeholder='Сообщение'/>
          </div>
        </div>
        <div className='form-button' onClick={handleClose}>
          Отправить
        </div>
        
      </div>

    </div>
  )
}