import './Footer.scss'
import mainLogo from '../../assets/icons/mainLogo.svg'
import telegram from '../../assets/icons/telegram.svg'
import vk from '../../assets/icons/vk.svg'

export default function Footer() {
  return(
    <div className='footer'>
      <img src={mainLogo} alt="main"/>
      <div className='footer-info'>
        Общество с ограниченной ответственностью"ТЕХНОЛОГИИ АРЕС", Юридический адрес организации 143003, Россия, Московская обл., г. Одинцово, ул. Маршала Неделина, д. 6А, э.7, ИНН 5032341299, КПП 503201001, ОГРН 1225000051540
      </div>
      <div className='socials'>
      <img src={telegram} alt="tg"/>
      <img src={vk} alt="vk"/>
      </div>
      <div className='rights'>
      ⓒ "ТЕХНОЛОГИИ АРЕС" 2022. Все права защищены.
      </div>
    </div>
  )
}